
<script>

import { log, errorHandler, webRequestManager } from 'organizer-common';
import { UserException, ExpectedException } from 'organizer-common';

import { registrationService } from '@/service/RegistrationService.js';

export default {
  name: 'RegistrationComponent',
  components: {
  },
  props: {
  },
  data: function() {
    return {
      // Initialize variables:
      registrationService: registrationService,
      formValid: false,
      emailAddress: "",
      emailAddressValid: false,
      errorMessage: null,
      registrationProcessing: false
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("RegistrationComponent.created(): Entered method");
    } finally {
      // Log message:
      console.log("RegistrationComponent.created(): Exiting method");
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    submitRegistration: async function() {
      // Try block:
      try {
        // Log message:
        log.message("RegistrationComponent.submitRegistration(): Entered method", { "email address": this.emailAddress });
        // Check email address:
        if(!this.emailAddress) {
          // Throw exception:
          throw new UserException({
            code: "registration.email.null",
            message: "Registration must have email address supplied",
            placeholder: "Email address may not be empty"
          });
        }
        // Set registration processing:
        this.registrationProcessing = true;
        // Clear error message
        this.errorMessage = null;
        // Call to request registration:
        await webRequestManager.request("post", "authorization", "/registration/v1", { "emailAddress": this.emailAddress});
        // Set registration requested:
        registrationService.registrationRequested = true;
        // Reset registration required:
        registrationService.registrationRequired = false;
        // Log message:
        log.message("RegistrationComponent.submitRegistration(): Request for registration submitted");
      } catch(error) {
        // Log message:
        log.message("RegistrationComponent.submitRegistration(): Encountered error while performing registration", { "message": error.message });
        // Check if this is a expected exception:
        if(error instanceof ExpectedException) {
          // Set error message
          this.errorMessage = error.placeholder;
        } else {
          // Set error message
          this.errorMessage = "Unable to complete registration";
        }
        // Handle error:
        errorHandler.handleError(error);
      } finally {
        // Reset registration processing:
        this.registrationProcessing = false;
        // Log message:
        log.message("RegistrationComponent.submitRegistration(): Exiting method");
      }
    },
    onChangeText: async function() {
      // Try block:
      try {
        // Log message:
        // log.message("ResourceCreateComponent.onChangeText(): Entered method");
        // Log message:
        // log.message("RegistrationComponent.onChangeText(): Event fired");
        this.formValid = this.$refs.registrationForm.checkValidity();
        // Check email address:
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.emailAddress)) {
            this.emailAddressValid = true;
        } else {
            this.emailAddressValid = false;
        }
        // Log message:
        // log.message("RegistrationComponent.onChangeText(): Form check complete", { "form valid": this.formValid, "email address valid": this.emailAddressValid });
      } finally {
        // Log message:
        // log.message("ResourceCreateComponent.onChangeText(): Exiting method");
      }
    }
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div style="max-width: 330px; margin: 32vh auto 0px auto;">
    <div v-if="registrationService.registrationRequired == false && registrationService.registrationComplete == false && registrationService.registrationRequested == false" class="d-flex align-items-center">
      <h6 class="mb-1">Checking registration status...</h6>
      <div class="ml-auto">
        <div class="spinner-border" style="width: 1.75rem; height: 1.75rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="registrationService.registrationRequired == true">
      <h5 class="text-center mb-3">Register for Early Access</h5>
      <form ref="registrationForm">
        <div class="form-group">
          <input type="email" class="form-control" placeholder="Enter email address" v-model="emailAddress" v-bind:class="{ 'is-invalid': emailAddress.length > 6 && !emailAddressValid }" @keyup="onChangeText()">
          <div class="invalid-feedback" v-if="emailAddress.length > 6 && !emailAddressValid">Please enter a valid email address</div>
        </div>
        <button type="button" class="btn btn-primary btn-lg btn-block mb-3" :disabled="!formValid" @click="submitRegistration()">
          <div v-if="registrationProcessing == false">Register</div>
          <div v-if="registrationProcessing == true" class="spinner-border" style="width: 1.75rem; height: 1.75rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </form>
    </div>
    <div v-if="registrationService.registrationRequested == true">
      <div class="alert alert-success" role="alert">Registration for this device has been submitted and is pending</div>
    </div>
    <div v-if="errorMessage">
      <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
    </div>
  </div>
</template>
