
import { organizerCommonPackageConfiguration } from 'organizer-common';
import { log } from 'organizer-common';
import { WebRequestManager } from 'organizer-common';
import { OrganizerClientWebRequestManager } from 'organizer-common';

export var webRequestService = new class WebRequestService {

  initialize() {
    // Try block:
    try {
      // Log message:
      console.log("WebRequestService.initialize(): Entered method");
      // Configure web request manager:
      organizerCommonPackageConfiguration.configure({
        webRequestManager: new OrganizerClientWebRequestManager({
          connection: new WebRequestManager({
            endpointEnvironmentVariableNameMap: {
              "test": "VUE_APP_WEB_ENDPOINT_TEST_ROOT_URL",
              "authorization": "VUE_APP_WEB_ENDPOINT_AUTHORIZATION_ROOT_URL",
              "communication": "VUE_APP_WEB_ENDPOINT_COMMUNICATION_ROOT_URL",
              "schedule": "VUE_APP_WEB_ENDPOINT_SCHEDULE_ROOT_URL",
              "storage": "VUE_APP_WEB_ENDPOINT_STORAGE_ROOT_URL",
              "synchronization": "VUE_APP_WEB_ENDPOINT_SYNCHRONIZATION_ROOT_URL"
            }
          })
        })
      });
      // Log message:
      log.message("WebRequestService.initialize(): Web Request Manager Initialized");
    } finally {
      // Log message:
      console.log("WebRequestService.initialize(): Exiting method");
    }
  }

}();
