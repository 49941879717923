
<script>

import { log } from 'organizer-common';

export default {
  name: 'TitleComponent',
  components: {
  },
  props: {
  },
  data: function() {
    return {
      // Initialize variables:
      contentStringArray: [],
      animationStarted: false,
      animationComplete: false,
      titlePrefixString: "Everything",
      currentTabName: "Everything",
      instructionArray: [
        {
          "action": "append",
          "content": "R",
          "delay": 250
        },
        {
          "action": "append",
          "content": "e",
          "delay": 250
        },
        {
          "action": "append",
          "content": "m",
          "delay": 250
        },
        {
          "action": "append",
          "content": "e",
          "delay": 150
        },
        {
          "action": "append",
          "content": "m",
          "delay": 100
        },
        {
          "action": "append",
          "content": "b",
          "delay": 100
        },
        {
          "action": "append",
          "content": "e",
          "delay": 100
        },
        {
          "action": "append",
          "content": "r",
          "delay": 100
        },
        {
          "action": "append",
          "content": " ",
          "delay": 1000
        },
        {
          "action": "append",
          "content": "t",
          "delay": 250
        },
        {
          "action": "append",
          "content": "o",
          "delay": 100
        },
        {
          "action": "append",
          "content": " ",
          "delay": 100
        },
        {
          "action": "append",
          "content": "d",
          "delay": 250
        },
        {
          "action": "append",
          "content": "o",
          "delay": 100
        },
        {
          "action": "append",
          "content": " ",
          "delay": 1000
        },
        {
          "action": "append",
          "content": "E",
          "delay": 275
        },
        {
          "action": "append",
          "content": "v",
          "delay": 275
        },
        {
          "action": "append",
          "content": "e",
          "delay": 250
        },
        {
          "action": "append",
          "content": "r",
          "delay": 250
        },
        {
          "action": "append",
          "content": "y",
          "delay": 200
        },
        {
          "action": "append",
          "content": "t",
          "delay": 100
        },
        {
          "action": "append",
          "content": "h",
          "delay": 100
        },
        {
          "action": "append",
          "content": "i",
          "delay": 100
        },
        {
          "action": "append",
          "content": "n",
          "delay": 100
        },
        {
          "action": "append",
          "content": "g",
          "delay": 100
        },
        {
          "action": "delete",
          "position": 14,
          "delay": 900
        },
        {
          "action": "delete",
          "position": 13,
          "delay": 200
        },
        {
          "action": "delete",
          "position": 12,
          "delay": 200
        },
        {
          "action": "delete",
          "position": 11,
          "delay": 200
        },
        {
          "action": "delete",
          "position": 10,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 9,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 8,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 7,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 6,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 5,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 4,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 3,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 2,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 1,
          "delay": 100
        },
        {
          "action": "delete",
          "position": 0,
          "delay": 75
        },
        {
          "action": "append",
          "content": " ",
          "delay": 75
        },
        {
          "action": "append",
          "content": '<img height="32" src="asset/title-simplified.png">',
          "delay": 500
        },
        {
          "action": "replace",
          "position": 11,
          "content": '<img height="32" src="asset/title-simplfied.png">',
          "delay": 2500
        }
      ]
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("TitleComponent.created(): Entered method");
    } finally {
      // Log message:
      console.log("TitleComponent.created(): Exiting method");
    }
  },
  mounted: async function() {
    // Try block:
    try {
      // Log message:
      console.log("TitleComponent.mounted(): Entered method");
      // Initiate typing:
      this.initiateTyping();
    } finally {
      // Log message:
      console.log("TitleComponent.mounted(): Exiting method");
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    initiateTyping: async function() {
      // Try block:
      try {
        // Log message:
        log.message("TitleComponent.initiateTyping(): Entered method");
        // Check animation started:
        if(this.animationStarted == false) {
          // Set animation started:
          this.animationStarted = true;
          // Start execution of instructions:
          this.onInstruction(0);
        }
      } finally {
        // Log message:
        log.message("TitleComponent.initiateTyping(): Exiting method");
      }
    },
    onInstruction: async function(index) {
      // Try block:
      try {
        // Log message:
        log.message("TitleComponent.onInstruction(): Entered method");
        // Get instruction:
        var instruction = this.instructionArray[index];
        // Start timer for delayed execution:
        setTimeout(() => { // Use of arrow function (lambda) here allows use of keyword 'this' to reference current component
          // Log message:
          log.message("TitleComponent.onInstruction(): Ready to execute instruction, action = ${instruction['action']}");
          // Check action:
          switch(instruction['action']) {
            case 'append': {
              // Log message:
              log.message("TitleComponent.onInstruction(): Appending to end of string", { "content": instruction['content'] });
              // Append content to end of content string array:
              this.contentStringArray.push(instruction['content']);
              // Break:
              break;
            }
            case 'replace': {
              // Log message:
              log.message("TitleComponent.onInstruction(): Replacing in string", { "content": instruction['content'], "position": instruction['position'] });
              // Replace content at position in content string array:
              this.contentStringArray[instruction['position']] = instruction['content'];
              // Break:
              break;
            }
            case 'delete': {
              // Log message:
              log.message("TitleComponent.onInstruction(): Deleting from string", { "position": instruction['position'] });
              // Delete content at position in content string array:
              this.contentStringArray.splice(instruction['position'], 1);
              // Break:
              break;
            }
          }
          // Log message:
          log.message("TitleComponent.onInstruction(): Checking for next instruction, index = ${index}, instruction list length = ${instructionList.length}");
          // Check for more instructions:
          if(index + 1 < this.instructionArray.length) {
            // Call to run the next instruction:
            this.onInstruction(index + 1);
          } else {
            // Set animation complete:
            this.animationComplete = true;
          }
        }, instruction['delay']); // Milliseconds
      } finally {
        // Log message:
        log.message("TitleComponent.onInstruction(): Exiting method");
      }
    },
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div>
    <h2 v-if="animationComplete == false" style="line-height: 42px;">
      <span v-for="contentString in contentStringArray" :key="contentString" v-html="contentString"></span>
    </h2>
    <h2 v-if="animationComplete == true" style="line-height: 42px;">
      <span>{{ titlePrefixString }}</span>
      <span>&nbsp;</span>
      <span><img height="32" src="asset/title-simplfied.png"></span>
    </h2>
  </div>
</template>
