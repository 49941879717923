
import { default as pino } from 'pino';

import { organizerCommonPackageConfiguration } from 'organizer-common';
import { log, LogFacadeConnectorToPino } from 'organizer-common';

export var logService = new class LogService {

  initialize() {
    // Try block:
    try {
      // Log message:
      console.log("LogService.initialize(): Entered method");
      // Configure log:
      organizerCommonPackageConfiguration.configure({
        log: new LogFacadeConnectorToPino({
          logger: pino({
            transport: {
              target: 'pino-pretty',
              options: {
                translateTime: true
              }
            }
          })
        })
      });
      // Log message:
      log.message("LogService.initialize(): Log Initialized");
    } finally {
      // Log message:
      console.log("LogService.initialize(): Exiting method");
    }
  }

}();
