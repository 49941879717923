
import { log, errorHandler, webRequestManager, ExpectedException } from 'organizer-common';

export var registrationService = new class RegistrationService {

  constructor() {
    // Try block:
    try {
      // Log message:
      console.log("RegistrationService.constructor(): Entered method");
      // Initialize registration required:
      this.registrationRequired = false;
      // Initialize registration complete:
      this.registrationComplete = false;
      // Initialize registration requested:
      this.registrationRequested = false;
    } finally {
      // Log message:
      console.log("RegistrationService.constructor(): Exiting method");
    }
  }

  async initialize() {
    // Try block:
    try {
      // Log message:
      log.message("RegistrationService.initialize(): Entered method");
      // Call to check registration:
      await webRequestManager.request("post", "authorization", "/check/v1", {  });
      // Set registration complete:
      this.registrationComplete = true;
      // Log message:
      log.message("RegistrationService.initialize(): Account is registered");
    } catch(error) {
      // Log message:
      log.message("RegistrationService.initialize(): Encountered exception", { "message": error.message });
      // Check if this is a expected exception:
      if(error instanceof ExpectedException) {
        // Check code:
        switch(error.code) {
          case 'authorization.registration.account.unapproved': {
            // Log message:
            log.message("RegistrationService.initialize(): Registration for account has not yet been approved");
            // Set registration requested:
            this.registrationRequested = true;
            // Break:
            break;
          }
          default: {
            // Log message:
            log.message("RegistrationService.initialize(): Other expected exception occurred, unknown code: " + error.code);
            // Set registration required:
            this.registrationRequired = true;
            // Break:
            break;
          }
        }
      } else {
        // Set registration required:
        this.registrationRequired = true;
        // Log message:
        log.message("RegistrationService.initialize(): Registration is required");
        // Handle error:
        errorHandler.handleError(error);
      }
    } finally {
      // Log message:
      log.message("RegistrationService.initialize(): Exiting method");
    }
  }

}();
