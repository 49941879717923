
import { organizerCommonPackageConfiguration } from 'organizer-common';
import { log } from 'organizer-common';
import { ErrorHandler } from 'organizer-common';

export var errorHandlerService = new class ErrorHandlerService {

  initialize() {
    // Try block:
    try {
      // Log message:
      console.log("ErrorHandlerService.initialize(): Entered method");
      // Configure error handler:
      organizerCommonPackageConfiguration.configure({
        errorHandler: new ErrorHandler()
      });
      // Log message:
      log.message("ErrorHandlerService.initialize(): Web Request Manager Initialized");
    } finally {
      // Log message:
      console.log("ErrorHandlerService.initialize(): Exiting method");
    }
  }

}();
