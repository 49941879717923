
import { organizerCommonPackageConfiguration } from 'organizer-common';
import { log } from 'organizer-common';
import { TokenGenerator } from 'organizer-common';

export var utilityService = new class UtilityService {

  initialize() {
    // Try block:
    try {
      // Log message:
      console.log("UtilityService.initialize(): Entered method");
      // Configure token generator:
      organizerCommonPackageConfiguration.configure({
        tokenGenerator: new TokenGenerator()
      });
      // Log message:
      log.message("UtilityService.initialize(): Web Request Manager Initialized");
    } finally {
      // Log message:
      console.log("UtilityService.initialize(): Exiting method");
    }
  }

}();
