
import { log, errorHandler, webRequestManager, ExpectedException } from 'organizer-common';

export var timezoneService = new class TimezoneService {

  async initialize() {
    // Try block:
    try {
      // Log message:
      log.message("TimezoneService.initialize(): Entered method");
      // Create January date time:
      var januaryDate = new Date(2020, 0, 1, 6, 0, 0);
      // Create July date time:
      var julyDate = new Date(2020, 6, 1, 6, 0, 0);
      // Call to check current timezone:
      await webRequestManager.request("post", "schedule", "/timezone/check/v1", { "januaryTimezoneOffsetSeconds": januaryDate.getTimezoneOffset() * 60, "julyTimezoneOffsetSeconds": julyDate.getTimezoneOffset() * 60 });
      // Log message:
      log.message("TimezoneService.initialize(): Timezone check was normal");
    } catch(error) {
      // Log message:
      log.message("TimezoneService.initialize(): Encountered exception", { "message": error.message });
      // Check if this is a expected exception:
      if(error instanceof ExpectedException) {
        // Check code:
        switch(error.code) {
          case 'timezone.incorrect': {
            // Log message:
            log.message("TimezoneService.initialize(): Timezone check found incorrect timezone, current account timezone locality identifier = ${exception.data['timezoneLocalityIdentifier']}");
            // Break:
            break;
          }
          case 'timezone.assigned': {
            // Log message:
            log.message("TimezoneService.initialize(): Timezone check found missing timezone and was able to assign default timezone, new account timezone locality identifier = ${exception.data['timezoneLocalityIdentifier']}");
            // Break:
            break;
          }
          case 'timezone.missing': {
            // Log message:
            log.message("TimezoneService.initialize(): Timezone check found missing timezone but was unable to assign default timezone");
            // Break:
            break;
          }
          default: {
            // Log message:
            log.message("TimezoneService.initialize(): Unknown code: " + error.code);
            // Break:
            break;
          }
        }
      } else {
        // Handle error:
        errorHandler.handleError(error);
      }
    } finally {
      // Log message:
      log.message("TimezoneService.initialize(): Exiting method");
    }
  }

}();
