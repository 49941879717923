
<script>

import { default as Vue } from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import { log } from 'organizer-common';

import { logService } from '@/service/LogService.js';
import { errorHandlerService } from '@/service/ErrorHandlerService.js';
import { utilityService } from '@/service/UtilityService.js';
import { webRequestService } from '@/service/WebRequestService.js';
import { timezoneService } from '@/service/TimezoneService.js';
import { registrationService } from '@/service/RegistrationService.js';
import { default as TabComponent } from '@/ui/TabComponent.vue';

// Import app SCSS:
import '@/app.scss';

Vue.config.productionTip = false;

// Install Vue plugin for Bootstrap:
Vue.use(BootstrapVue);
// Install Vue plugin for Bootstrap Icons:
Vue.use(IconsPlugin);

export default {
  name: 'OrganizerApplication',
  components: {
    TabComponent
  }
}

// Initialize log service:
logService.initialize();

// Initialize error handler service:
errorHandlerService.initialize();

// Initialize utility service:
utilityService.initialize();

// Initialize web request service:
webRequestService.initialize();

// Initialize timezone service:
timezoneService.initialize();

// Initialize registration service:
registrationService.initialize();

// Log message:
log.message("OrganizerApplication.vue: Organizer Application Initialized");

</script>

<style lang="scss">

</style>

<template>
  <div>
    <TabComponent/>
  </div>
</template>
