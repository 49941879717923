
<script>

import Vue from 'vue';
import VueRouter from 'vue-router';

import { log } from 'organizer-common';

import { registrationService } from '@/service/RegistrationService.js';
import { default as TitleComponent } from '@/ui/block/TitleComponent.vue';
import { default as RegistrationComponent } from '@/ui/page/RegistrationComponent.vue';

// Install plugin for Vue Router:
Vue.use(VueRouter);

export default {
  name: 'TabComponent',
  components: {
    RegistrationComponent,
    TitleComponent
  },
  data: function() {
    return {
      // Initialize variables:
      registrationService: registrationService
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      log.message("TabComponent.created(): Entered method", { '$route': this.$route });
      // Check route
      if(this.$route.path == "/") {
        // Check last path:
        if(localStorage["lastPath"]) {
          // Log message:
          log.message("TabComponent.created(): Redirecting to last path", { 'last path': localStorage["lastPath"] });
          // Redirect to last path:
          this.$router.replace({ path: localStorage["lastPath"] });
        }
      }
    } finally {
      // Log message:
      log.message("TabComponent.created(): Exiting method");
    }
  },
  watch: {
    '$route': function(to, from) {
      // Try block:
      try {
        // Log message:
        log.message("TabComponent.watch($route): Entered method", { 'to': to, 'from': from });
        // Set last path:
        localStorage["lastPath"] = to.path;
        // Log message:
        log.message("TabComponent.watch($route): Set last path", { 'last path': localStorage["lastPath"] });
      } finally {
        // Log message:
        log.message("TabComponent.watch($route): Exiting method");
      }
    }
  },
  router: new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/note',
        name: 'Note',
        // route level code-splitting
        // this generates a separate chunk (*.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "note" */ './tab/NoteComponent.vue')
      },
      {
        path: '/task',
        name: 'Task',
        // route level code-splitting
        // this generates a separate chunk (*.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "task" */ './tab/TaskComponent.vue')
      },
      {
        path: '/shop',
        name: 'Shop',
        // route level code-splitting
        // this generates a separate chunk (*.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "shop" */ './tab/ShopComponent.vue')
      }
    ]
  })
}

</script>

<style lang="scss">

.router-link-active {
  font-weight: bold;
}

</style>

<template>
  <div>
    <div class="container-fluid px-0">
      <div class="row no-gutters">
        <div class="col-sm-11 col-md-10 col-lg-9" style="max-width: 900px; margin: 0px auto;">
          <div class="container-fluid">
            <div v-if="registrationService.registrationComplete == true">
              <div style="margin-top: 32vh;">
                <TitleComponent/>
              </div>
              <router-view/>
            </div>
            <RegistrationComponent v-if="registrationService.registrationComplete != true"></RegistrationComponent>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 100px;"></div>
    <div class="fixed-bottom">
      <!-- <div>Messages Here</div> -->
      <nav class="navbar navbar-expand navbar-dark bg-dark">
        <ul class="navbar-nav justify-content-center align-items-center w-100">
          <li class="nav-item">
            <router-link class="nav-link" to="/note"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Notes')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" -->
              <div class="nav-custom-spacing">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                </svg>
              </div>
            </router-link>
          </li>
<!--
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedNoteRoutePath.toUrl()" [routerLinkActive]="'active'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedNoteRoutePath.toUrl()" [routerLinkActive]="'active'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-journal-bookmark-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedNoteRoutePath.toUrl()" [routerLinkActive]="'active'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-journal-bookmark" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedTaskRoutePath.toUrl()" [routerLinkActive]="'active'" (mouseenter)="titleService.tabHoverEventStreamController.add('Tasks')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
              </svg>
            </a>
          </li>
-->
          <li class="nav-item">
            <router-link class="nav-link" to="/task"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Tasks')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" -->
              <div class="nav-custom-spacing">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-ui-checks" viewBox="0 0 16 16">
                  <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/shop"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Shopping')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" -->
              <div class="nav-custom-spacing">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24" height="24" fill="currentColor" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                  <path id="XMLID_1732_" d="M91.8,27.3L81.1,61c-0.8,2.4-2.9,4-5.4,4H34.4c-2.4,0-4.7-1.5-5.5-3.7L13.1,19H4c-2.2,0-4-1.8-4-4
                    s1.8-4,4-4h11.9c1.7,0,3.2,1.1,3.8,2.7L36,57h38l8.5-27H35.4c-2.2,0-4-1.8-4-4s1.8-4,4-4H88c1.3,0,2.5,0.7,3.2,1.7
                    C92,24.7,92.2,26.1,91.8,27.3z M36.4,70.3c-1.7,0-3.4,0.7-4.6,1.9c-1.2,1.2-1.9,2.9-1.9,4.6c0,1.7,0.7,3.4,1.9,4.6
                    c1.2,1.2,2.9,1.9,4.6,1.9s3.4-0.7,4.6-1.9c1.2-1.2,1.9-2.9,1.9-4.6c0-1.7-0.7-3.4-1.9-4.6C39.8,71,38.1,70.3,36.4,70.3z M72.3,70.3
                    c-1.7,0-3.4,0.7-4.6,1.9s-1.9,2.9-1.9,4.6c0,1.7,0.7,3.4,1.9,4.6c1.2,1.2,2.9,1.9,4.6,1.9c1.7,0,3.4-0.7,4.6-1.9
                    c1.2-1.2,1.9-2.9,1.9-4.6c0-1.7-0.7-3.4-1.9-4.6S74,70.3,72.3,70.3z"/>
                </svg>
              </div>
            </router-link>
          </li>
<!--
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedShopRoutePath.toUrl()" [routerLinkActive]="'active'" (mouseenter)="titleService.tabHoverEventStreamController.add('Shopping')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedShopRoutePath.toUrl()" [routerLinkActive]="'active'" (mouseenter)="titleService.tabHoverEventStreamController.add('Shopping')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24" height="24" fill="currentColor" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                <path id="XMLID_1989_" d="M88,29H77c0,0-0.1-0.3-0.1-0.4L58,5.5c-1.4-1.7-3.9-2-5.6-0.6s-2,4.1-0.6,5.8L66.7,29H25.3l14.9-18.3
                  C41.6,9,41.4,6.4,39.6,5c-1.7-1.4-4.2-1.2-5.6,0.5L15.1,28.7c0,0.1-0.1,0.3-0.1,0.3H4c-2.2,0-4,1.4-4,3.6v15C0,49.8,1.8,52,4,52h1.3
                  l7.5,30.4c0.8,3.5,3.9,5.6,7.5,5.6h51.4c3.6,0,6.6-2.2,7.5-5.6L86.7,52H88c2.2,0,4-2.2,4-4.4v-15C92,30.4,90.2,29,88,29z M8,37h76v7
                  h-0.4H8.4H8V37z M71.5,80h-51l-7-28h65L71.5,80z"/>
              </svg>
            </a>
          </li>
-->
          <li class="nav-item navbar-brand m-0">
            <router-link class="nav-link" to="/"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Everything')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" -->
              <div class="nav-custom-spacing">
                <img src="asset/logo-dark.png" width="30" height="30">
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/search"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Search')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" -->
              <div class="nav-custom-spacing">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24" height="24" fill="currentColor" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                  <path id="XMLID_1102_" d="M57.8,2.2c-17.1,0-31,14.1-31,31.3c0,7.3,2.5,13.9,6.6,19.3L4,82.6c-1.7,1.8-1.7,4.6,0,6.3
                    c0.9,0.9,2,1.3,3.1,1.3c1.2,0,2.3-0.4,3.2-1.3l29.4-29.8c5.1,3.7,11.3,5.8,18,5.8c17.1,0,31-14.1,31-31.3S74.9,2.2,57.8,2.2z
                    M57.8,57c-12.7,0-23-10.5-23-23.4c0-12.9,10.3-23.4,23-23.4c12.7,0,23,10.5,23,23.4C80.8,46.5,70.5,57,57.8,57z M73.3,34
                    c-0.2,1.7-1.7,3-3.4,3c-0.2,0-0.3,0-0.5,0c-1.9-0.3-3.2-2-3-3.9c0.7-5.2-5.1-7.9-5.4-8c-1.7-0.8-2.5-2.9-1.7-4.6s2.8-2.5,4.6-1.8
                    C64.3,18.8,74.7,23.6,73.3,34z M66,41.6c0.7,0.7,1.2,1.8,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8c-0.7,0.7-1.8,1.2-2.8,1.2
                    c-1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.8-1.2-2.8c0-1,0.4-2.1,1.2-2.8c0.7-0.7,1.8-1.2,2.8-1.2C64.2,40.4,65.2,40.8,66,41.6z"/>
                </svg>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/calendar"><!-- (mouseenter)="titleService.tabHoverEventStreamController.add('Calendar')" (mouseleave)="titleService.tabHoverEventStreamController.add(null)" --><!-- or Reminders -->
              <div class="nav-custom-spacing">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" style="margin: 4px 0px 0px 0px;" to="/calendar">
              <div class="nav-custom-spacing">
                <svg class="bi bi-chat-square-dots-fill" width="24" height="24" fill="currentColor" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="m2 0c-1.1046 0-2 0.89543-2 2v8c0 1.1046 0.89543 2 2 2h2.5c0.31476 0 0.61193 0.14858 0.80078 0.40039l1.8984 2.5332c0.4 0.53333 1.2016 0.53333 1.6016 0l1.8984-2.5332c0.18885-0.25181 0.48602-0.40039 0.80078-0.40039h2.5c1.1046 0 2-0.89543 2-2v-8c0-1.1046-0.89543-2-2-2h-12zm2.0078 2h0.48438c0.5581 0 1.0078 0.44421 1.0078 0.99609v2.0078c0 0.55188-0.44971 0.99609-1.0078 0.99609h-0.48438c-0.5581 0-1.0078-0.44421-1.0078-0.99609v-2.0078c0-0.55188 0.44971-0.99609 1.0078-0.99609zm6 0h0.48438c0.5581 0 1.0078 0.44421 1.0078 0.99609v2.0078c0 0.55188-0.44971 0.99609-1.0078 0.99609h-0.48438c-0.5581 0-1.0078-0.44421-1.0078-0.99609v-2.0078c0-0.55188 0.44971-0.99609 1.0078-0.99609z"/>
                </svg>
              </div>
            </router-link>
          </li>
<!--
          <li class="nav-item">
            <a class="nav-link" [routerLink]="RoutePaths.simplifiedCalendarRoutePath.toUrl()" [routerLinkActive]="'active'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link position-relative" [routerLink]="RoutePaths.simplifiedCommunicationRoutePath.toUrl()" [routerLinkActive]="'active'">
              <div *ngIf="unreadCommunicationCount > 0" class="position-absolute badge badge-danger mx-1" style="top: 0px; right: 0px;">{{unreadCommunicationCount}}</div>
              <div class="nav-custom-spacing">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                </svg>
              </div>
            </a>
          </li>
-->
        </ul>
      </nav>
    </div>
  </div>
</template>
